<template>
  <v-layout row wrap mb-4 pb-4>
    <v-layout row>
      <v-flex xs3 sm2 md1 px-0 mx-0>
        <v-card :class="{'fixed-large': $vuetify.breakpoint.lgAndUp, 'fixed-small': $vuetify.breakpoint.mdAndDown, 'fixed-xs': $vuetify.breakpoint.xs}">
          <v-list dense class="py-1 grey darken-4" two-line router :to="item.link" v-for="item in items" :key="item.title" pb-4>
            <v-list-tile router :to="item.link" exact>
              <v-list-tile-content>
                <v-list-tile-action class="justify-space-between">
                  <v-icon class="justify-space-between" right>{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-title class="text-xs-center pb-4">{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex pb-4 mb-4 pt-0 mt-0 px-0 ml-2 xs12>
        <v-card min-width="250px" max-width="600px" pb-4 mb-4 >
          <v-card-title class="headline black justify-center py-1">
              MLW 2024
          </v-card-title>
          <v-img src="/assets/mlwticket.jpg" />
          <v-card-text class="text-xs-center text-sm-left grey--text">
            <div>
              Tickets for the MLM Weekend event are distributed through
              <a
                href="https://buytickets.at/mlw2024/1301563"                
                target="_blank"
                style="color:#fff"
                rel="noreferrer"
              >Ticket Tailor</a>.
            </div>
            <div class="mt-3 mb-3 caption">
              Purchasing the ticket should be easy and prior the event we'll send you an email with more details.
              <br />Please don't forget to follow us on Facebook to see our latest updates!
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              href="https://buytickets.at/mlw2024/1301563"
              target="_blank"
              rel="noreferrer"
              class="primary grey--text text--darken-4 ma-4"
              block
            >
              <v-icon left>mdi-ticket-account</v-icon>Buy a ticket
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<style>
.v-list__tile__content {
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}

.fixed-xs {
  position: fixed;
  left: 0px;
  z-index: 1;
  opacity: 0.75;
}

.fixed-small {
  position: fixed;
  left: 0px;
  z-index: 1;
}

.fixed-large {
  position: fixed;
  z-index: 1;
}
</style>
<script>
export default {
  metaInfo: {
    titleTemplate: "%s | Leather Weekend",
    link: [{ rel: "canonical", href: "https://www.manchesterleathermen.com/weekend/tickets" }],
      meta: [
        { itemprop: "name", content: "MLM Weekend ← Manchester Leathermen " },
        { itemprop: "description", content: "Manchester Leather Weekend is back! Grab your ticket before they're gone" }
      ]
  },
  data: () => ({
    items: [
      { title: "Info", icon: "mdi-information-outline", link: "/weekend" },
      {
        title: "Events",
        icon: "mdi-calendar-star",
        link: "/weekend/events"
      },
      { title: "Tickets", icon: "mdi-ticket-account", link: "/weekend/tickets" }
    ]
  })
};
</script>

